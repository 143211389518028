'use client'

import { faClock } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";

function Header({ toggleSidebar }) {

  const [school, setSchool] = useState(null)


  useEffect(() => {
    let state = localStorage.getItem("state")
    if(!state){
      window.location.href = "/login"
    }
    let school =  JSON.parse(state).school
    
    setSchool(school)
  }, []);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  return (
    <>
      <header className="header">
        <section>
          <button onClick={toggleSidebar} className="sidebar-toggle">
            ☰
          </button>
        </section>

        <section>
          <div>
            <img src="/images/logo.png" alt="VidyalayHelp Logo" />
            <p>
              <span>VidyalayHelp</span>
              <span>The School Management System</span>
            </p>
          </div>
        </section>

        <section className="dateTime">
          <p>🟢Welcome, {school ? school.name: ''}</p>
        </section>

        <button onClick={scrollToTop} id="goUp">
          &uarr;
        </button>
      </header>
    </>
  );
}

export default Header;
